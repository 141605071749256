import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  Text,
  Link
} from '@chakra-ui/react'
import { mdiAccountCircleOutline, mdiLogout } from '@mdi/js'
import React, { useContext, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useLibrary, useSDK } from '../hooks/useData.js'
import { EnvironmentContext } from './providers/EnvironmentProvider.js'
import { PocketNav } from './PocketNav.js'

export const Wrapper: FlexProps = {
  width: 'full',
  background: 'white',
  position: 'sticky',
  paddingTop: '3',
  paddingBottom: '3',
  paddingLeft: '3',
  paddingRight: '22px',
  top: 0,
  boxShadow: 'base',
  alignItems: 'center',
  maxHeight: '14',
  zIndex: 200
}

const Navbar = () => {
  const navigate = useNavigate()
  const tenant = useSDK('auth.tenant')
  const env = useContext(EnvironmentContext)
  const library = useLibrary()
  const { user, logout } = useSDK('auth')
  const ref = useRef<HTMLDivElement>()

  return (
    <Flex {...Wrapper} ref={ref} as='header' id='navbar'>
      <Flex align='center' w='full' id='navigation-horizontal'>
        <Link href={env.xmCloudDashboard} isExternal ml={1} mr={2}>
          <Image src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud' />
        </Link>
        <PocketNav />
        <Box borderRight='1px solid' borderColor='gray.200' h='48px' mx={3} />
        <Heading size='sm' mr={2}>
          Components
        </Heading>
        {tenant && <Text mr={6}>{tenant.displayName}</Text>}

        {library && (
          <>
            <ButtonGroup as='nav' size='sm' variant='navHorizontal'>
              <Button
                onClick={() => navigate(`${library.getPath()}/collections`)}
                variant={
                  Boolean(
                    location.pathname.match(
                      /^(?!\/libraries\/[^/]+\/(?:datasources|styles|publishing|settings|plugins))/
                    )
                  )
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Components
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/styles`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/styles`) ? 'navHorizontalActive' : 'navHorizontal'
                }
              >
                Styles
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/datasources`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/datasources`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Data sources
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/publishing/components/staged`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/publishing`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Publishing
              </Button>
              <Button
                onClick={() => navigate(`${library.getPath()}/settings`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/settings`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Settings
              </Button>
              {env.inventoryEnv === 'staging' && (
                <Button
                  onClick={() => navigate(`${library.getPath()}/plugins`)}
                  variant={
                    location.pathname.startsWith(`${library.getPath()}/plugins`)
                      ? 'navHorizontalActive'
                      : 'navHorizontal'
                  }
                >
                  Plugins
                </Button>
              )}
            </ButtonGroup>
          </>
        )}

        <Flex ml='auto' minW={0}>
          <Flex align='center' ml='5'>
            <Menu isLazy>
              <Tooltip label={user?.email}>
                <MenuButton as={Avatar} layerStyle='interactive.fill' size='sm' name={user?.name} src={user?.picture} />
              </Tooltip>
              <MenuList>
                {user && (
                  <>
                    <Box px='3.5' py='2'>
                      <Text fontWeight={600}>{user.name}</Text>
                      <Text fontSize='13px' color='blackAlpha.500'>
                        {user.email}
                      </Text>
                    </Box>
                    <MenuItem
                      as='a'
                      href={`https://portal${env.inventoryEnv === 'production' ? '' : '-' + env.inventoryEnv}.${
                        env.sitecorePostfix
                      }/profile?organization=${tenant.organizationId}`}
                      target='_blank'
                      icon={
                        <Icon boxSize='22px'>
                          <path d={mdiAccountCircleOutline} />
                        </Icon>
                      }
                    >
                      Manage account
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem
                  icon={
                    <Icon boxSize='22px'>
                      <path d={mdiLogout} />
                    </Icon>
                  }
                  onClick={() => logout({ returnTo: window.location.origin })}
                >
                  Log out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Navbar
