import { FC, useContext, useEffect } from 'react'
import { useSDK } from '../hooks/useData.js'
import { EnvironmentContext, getEnvironment } from './providers/EnvironmentProvider.js'

export const PocketNav: FC = () => {
  const { tenant } = useSDK('auth')
  const env = useContext(EnvironmentContext)
  const environment = env.pocketNavEnv
  const tenantName = tenant.name
  const organizationId = tenant.organizationId

  useEffect(() => {
    if (getEnvironment() !== 'ci' && getEnvironment() !== 'localhost') {
      import(
        /* @vite-ignore */
        'https://sitecoredelivr.sitecorecloud.io/npm/@sitecore-ui/xmc-pocket-nav@latest/dist/' + 'entry.js'
      ).catch(() => {})
    }
  }, [])

  return <xmc-pocket-nav environment={environment} tenant={tenantName} organization={organizationId} />
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'xmc-pocket-nav': any
    }
  }
}
